import "@babel/polyfill";

import Vue from "vue";
import "@/prototype";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "normalize.css/normalize.css";
import "./styles/global.styl";
import {ValidationProvider, ValidationObserver} from "@/Validator";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Print from './utils/print';
Vue.use(Print);
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);


import './config/rem'
import './config/common.css'

Vue.prototype.$eventBus = new Vue()

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
//滚动条样式美化
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

Vue.use(PerfectScrollbar);

//滚动跳滚到.
var VueScrollTo = require('vue-scrollto');
// You can also pass in the default options
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: -100,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

//引入iconfont symbol图标
import "./assets/fonts/iconfont";


Vue.use(ElementUI);

Vue.config.productionTip = false;

import 'animate.css';

//路由守卫鉴权
const whiteList = [
  "result-oem",
  "test-InvoiceUpload-oem",
  "UploadTable-oem",
  "oemGuard"
]; //不需要验证的路由name

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem("authorization");
  if (token) {
    next();
  } else {
    if (whiteList.indexOf(to.name) !== -1||1===1) {//有token了 去掉路由白名单
      // 在免登录白名单，直接进入
      next();
    } else {
      //next('/passport/login');
      next({name: "login", query: {redirect: to.path}}); // 否则全部重定向到登录页
    }
  }
});


//根据domain设置自定义版本
const debug_customization = ''//调试用

const domain = window.location.hostname.toLowerCase();

if (debug_customization == 'hy.ai-tax.cn' || domain == 'hy.ai-tax.cn') {
  store.commit('setCustomization', {customization: 'hy'})
} else if (debug_customization == 'xr.ai-tax.cn' || domain == 'xr.ai-tax.cn') {
  store.commit('setCustomization', {customization: 'xr'})
} else if (debug_customization == 'nmhz.ai-tax.cn' || domain == 'nmhz.ai-tax.cn') {
  store.commit('setCustomization', {customization: 'nmhz'})
} else {
  store.commit('setCustomization', {customization: ''})
}


(function() {

  if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
    handleFontSize();
  } else {
    if (document.addEventListener) {
      document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
    } else if (document.attachEvent) {
      document.attachEvent("WeixinJSBridgeReady", handleFontSize);
      document.attachEvent("onWeixinJSBridgeReady", handleFontSize);  }
  }
  function handleFontSize() {
    // 设置网页字体为默认大小
    WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize' : 0 });
    // 重写设置网页字体大小的事件
    WeixinJSBridge.on('menu:setfont', function() {
      WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize' : 0 });
    });
  }
})();

window.store = store;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");


