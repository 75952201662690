const routerConfig = [
  //oem
  // V1
  {
    path: '/oem/:accessToken/:uuid/:zq?',
    name: 'oemGuard',
    component: () =>
      import("@/pages/oem/OemGuard"),
    props: true,
    children: [
      //查看报表
      {
        path: 'auth', ///:uuid/:productID/:productName/upload-list/:zq/:zqLocation/:dataPeriod/:version/:user/:isShanDong/:deleteDataType?
        component: () =>
          import('@/pages/oem/UploadTable'),
        name: 'UploadTable-oem',
        hidden: true,
        props: true,
      },
      //发票数据采集后的报告生成页面
      {
        path: 'InvoiceUpload',
        component: () =>
          import('@/pages/oem/invoiceUpload/invoiceUpload'),
        name: 'test-InvoiceUpload-oem',
        props: true
      },
      //查看报告
    ],
  },
  // V2
  {
    path: '/oemV2/:zq?',
    name: 'oemGuardV2',
    component: () =>
      import("@/pages/oem/OemGuardV2"),
    props: true,
    children: [
      //查看报表
      {
        path: 'auth', ///:uuid/:productID/:productName/upload-list/:zq/:zqLocation/:dataPeriod/:version/:user/:isShanDong/:deleteDataType?
        component: () =>
          import('@/pages/oem/UploadTable'),
        name: 'UploadTable-oem',
        hidden: true,
        props: true,
      }
    ],
  },
];

export default routerConfig;
