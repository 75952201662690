import axios from "axios";
import router from "@/router";
import {Message} from 'element-ui'
import routes from "@/config/routes";

const publicURLs = []
const base = process.env.VUE_APP_API_ROOT;

//如果是 税务师 的api ${base}/plat/ 全部替换成 ${base}/firm/
axios.interceptors.request.use(
  config => {
    if(window.location.href.includes("/pdfFrontGenerator/")&&router.app.$route.params.from==='shiwusuo'){
      config.url = config.url.replace(`${base}/plat/`,`${base}/taxfirm/`)
    }
    if(window.location.href.includes("/wordResultFrontGenerator/")&&router.app.$route.params.from==='shiwusuo'){
      config.url = config.url.replace(`${base}/plat/`,`${base}/taxfirm/`)
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

//全局配置请求头+token
axios.interceptors.request.use(
  config => {
    // 判断是否存在token，如果存在的话，则每个http header都加上token
    let token = localStorage.getItem("authorization");
    let shareAuthorization = localStorage.getItem("Share-Authorization");
    if (token && config.url.indexOf(base) > -1) {
      let isPublicURLs = publicURLs.some(url => {
        return config.url.startsWith(url)
      });

      if (!isPublicURLs) {
        config.headers.Authorization = `${token}`;
      }


    }
    if(shareAuthorization){
      config.headers['Share-Authorization'] = shareAuthorization;
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

// 响应拦截器
axios.interceptors.response.use(response => {
  if (response.status === 200) {
    //token失效
    // if (response.data && response.data.msgCode == "1001") {
    //   router.push({name: "login"});
    //   return Promise.reject(response);
    // } else {
      return Promise.resolve(response);
    // }
  } else {
    return Promise.reject(response);
  }
});

//不加这个IE会缓存GET请求
axios.defaults.headers.get['Cache-Control'] = 'no-cache'
axios.defaults.headers.get['Pragma'] = 'no-cache'

export default axios;


/**
 * get方法，对应get请求
 * @param {String} url 请求的url地址
 * @param {Object} params 请求时携带的参数
 * @param {String} autoWarning
 * @returns {Promise<unknown>}
 */
export function get(url, params, autoWarning = true) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params
      })
      .then(res => {
        if (autoWarning && res.data.msgCode != "1" && res.data.msgInfo) {
          Message.warning({message: res.data.msgInfo});
        }
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params, isAutoWaring = true, config) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, config)
      .then(res => {
        if (isAutoWaring && res.data.msgCode != "1" && res.data.msgInfo) {
          Message.warning({message: res.data.msgInfo});
        }
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}
