import Vue from "vue";
import Vuex from "vuex";
import { state, mutations, actions } from "./store";
import createCache from "vuex-cache";

// import Result from "@/pages/Result/store";
// import ResultEditor from "@/pages/ResultEditor/store";
// import PdfResult from "@/pages/PdfResult/store";
import oem from "@/pages/oem/store";
// import ThreeYearResult from "@/pages/Result/ThreeYearResult/store";

//import createLogger from "../../../src/plugins/logger";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    oem
  },
  state,
  mutations,
  actions,
  strict: debug,
  plugins: debug ? [createCache()] : [createCache()]
});
